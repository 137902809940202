import logo from './logo.svg';
import './App.css';
import Navbar from './Navbar';
import Homepage from './homepage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Product from './product';
import CartItems from './cart';
import Products from './products';
import { CartProvider  } from './context';



import Footer from './footer';
import { useState } from 'react';
import Checkout from './checkout';

function App() {

  return (
    <>
     <CartProvider  >
    
    
    <BrowserRouter>
    <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="product" element={<Product />} />
        <Route path="products" element={<Products />} />
        <Route path="cart" element={<CartItems />} />
        <Route path="checkout" element={<Checkout />} />
      </Routes>
    </BrowserRouter>

    
    

    <Footer />


    </CartProvider >


    
    </>


  );
}

export default App;
