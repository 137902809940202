import React, { useContext } from "react";

import { Link, useLocation } from 'react-router-dom'




import { allproducts } from "./data";


import { useCart  } from "./context";


export default function Products(props){
    const location = useLocation()
    const {id,catName} = location.state
    const { cartDispatch } = useCart();
    const productstoshow = allproducts.filter(product=>product.categoryid===id)
    console.log(productstoshow)

    const addToCart = item=>{
        item.quantity = item.quantity + 1
        cartDispatch({ type: 'ADD_ITEM', payload: item });
    }

    return(
        <>
        <div class="container-fluid pt-5 pb-3">
        
        <div class="row px-xl-5">
            <div class="col-12">
                <nav class="breadcrumb bg-light mb-30">
                    <Link class="breadcrumb-item text-dark" to="/">Home</Link>
                    <span class="breadcrumb-item active">{catName}</span>
                </nav>
            </div>
        </div>
        <h2 class="section-title position-relative text-uppercase mx-xl-5 mb-4"><span class="bg-secondary pr-3">{catName}</span></h2>
        <div class="row px-xl-5">
            {productstoshow.map(item=>{
                return(
                    <div class="col-lg-3 col-md-4 col-sm-6 pb-1" key={item.product_id}>
                    <div class="product-item bg-light mb-4">
                        <div class="product-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src={item.Image} alt="" />
                            <div class="product-action">
                                <a class="btn btn-outline-dark btn-square"  onClick={()=>addToCart(item)}><i class="fa fa-shopping-cart"></i></a>
                                
                            </div>
                        </div>
                        <div class="text-center py-4">
                            <a class="h6 text-decoration-none " href="">{item.productName}</a>
                            <div class="d-flex align-items-center justify-content-center mt-2">
                                <h5>£{item.price}</h5><h6 class="text-muted ml-2"></h6>
                            </div>
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small>(99)</small>
                            </div>
                        </div>
                    </div>
                </div>
                )
            })}
           
        
          
            
           
        
         
         
        </div>
    </div>
        </>
    )
}