import React, { useContext } from "react";


import { useCart  } from "./context";
import { Link } from "react-router-dom";

import { allproducts } from "./data";


export default function CartItems(){

    const { cartState, cartDispatch } = useCart();
    

  const removeItem = item => {
   
    if(item.quantity>1){
        cartDispatch({type:'UPDATE_ITEM_QUANTITY',payload:{id:item.product_id,quantity:item.quantity-1}})
    }
    else{
        cartDispatch({ type: 'REMOVE_ITEM', payload: item });
    }
  
  };
  const increaseQuantity = item =>{
   
    // const productstoupdate = cartState.cartItems.find(product=>product.product_id===item.product_id)
    // productstoupdate.quantity = productstoupdate.quantity + 1
    const quantity = item.quantity + 1
  
    cartDispatch({type:'UPDATE_ITEM_QUANTITY',payload:{id:item.product_id,quantity:quantity}})
  }

  const calculateTotalPrice = () => {
    return cartState.cartItems.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  };


    return (
        <>
        <div class="container-fluid">
        <div class="row px-xl-5">
            <div class="col-12">
                <nav class="breadcrumb bg-light mb-30">
                <Link class="breadcrumb-item text-dark" to="/">Home</Link>
                    <span class="breadcrumb-item active">Cart</span>
                </nav>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row px-xl-5">
            <div class="col-lg-8 table-responsive mb-5">
                <table class="table table-light table-borderless table-hover text-center mb-0">
                    <thead class="thead-dark">
                        <tr>
                            <th>Products</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody class="align-middle">
                        {cartState.cartItems.map(item=>{
                            return(
                                <tr>
                                <td class="align-middle"><img src={item.Image} alt="" style={{width: '50px'}} /> {item.productName}</td>
                                <td class="align-middle">£{item.price}</td>
                                <td class="align-middle">
                                    <div class="input-group quantity mx-auto" style={{width: '100px'}}>
                                        <div class="input-group-btn">
                                            <button class="btn btn-sm btn-primary btn-minus" onClick={() => removeItem(item)} >
                                            <i class="fa fa-minus" ></i>
                                            </button>
                                        </div>

                                        <input type="text" class="form-control form-control-sm bg-secondary border-0 text-center" value={item.quantity} />
                                        <div class="input-group-btn">
                                            <button class="btn btn-sm btn-primary btn-plus" onClick={()=>increaseQuantity(item)}>
                                                <i class="fa fa-plus" ></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td class="align-middle">{item.price*item.quantity}</td>
                                <td class="align-middle"><button class="btn btn-sm btn-danger" onClick={() => removeItem(item)}><i class="fa fa-times"></i></button></td>
                            </tr>
                            )
                        })}
                    
                   
                     
                   
                    </tbody>
                </table>
            </div>
            <div class="col-lg-4">
                {/* <form class="mb-30" action="">
                    <div class="input-group">
                        <input type="text" class="form-control border-0 p-4" placeholder="Coupon Code" />
                        <div class="input-group-append">
                            <button class="btn btn-primary">Apply Coupon</button>
                        </div>
                    </div>
                </form> */}
                <h5 class="section-title position-relative text-uppercase mb-3"><span class="bg-secondary pr-3">Cart Summary</span></h5>
                <div class="bg-light p-30 mb-5">
                    <div class="border-bottom pb-2">
                        <div class="d-flex justify-content-between mb-3">
                            <h6>Subtotal</h6>
                            <h6>£{calculateTotalPrice()}</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <h6 class="font-weight-medium">Shipping</h6>
                            <h6 class="font-weight-medium">£6</h6>
                        </div>
                    </div>
                    <div class="pt-2">
                        <div class="d-flex justify-content-between mt-2">
                            <h5>Total</h5>
                            <h5>£{calculateTotalPrice() + 6}</h5>
                        </div>
                        <Link to='/checkout'>
                        <button class="btn btn-block btn-primary font-weight-bold my-3 py-3">Proceed To Checkout</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}