import React,{useState} from "react";
import { Link } from 'react-router-dom';
import { allproducts } from "./data";




const cdata =[ 
    {
        "categoryid": 100,
        "categoryName": "Beauty",
        "Image": "https://innersensebeauty.com/wp-content/uploads/2021/08/innersense-organic-beauty-refill-pouches-are-here-500x500.png",
        },
        {
        "categoryid": 200,
        "categoryName": "Grocery",
        "Image": "https://www.freepngimg.com/thumb/grocery/41619-7-groceries-free-download-image-thumb.png",
        },
        {
        "categoryid": 300,
        "categoryName": "Cleaning",
        "Image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9D_1S3SUfTzf_pIQB14UBupPP27u4W008YkZJVGiymxuR2vCOYxQV7LmVe-FoFcsjg2Q&usqp=CAU",
        },
        {
        "categoryid": 400,
        "categoryName": "Home & Kitchen",
        "Image": "https://www.downtownstores.co.uk/images/categories/89.jpg",
        },
        {
        "categoryid": 500,
        "categoryName": "Stationary",
        "Image": "https://practiceindex.co.uk/media/catalog/category/stationary_1.jpg",
        },
        {
        "categoryid": 600,
        "categoryName": "DIY Tools",
        "Image": "https://cdn11.bigcommerce.com/s-1qmaqa25jp/images/stencil/500x659/products/22368/58040/13174_1__66980.1688384506.jpg?c=1",
        },
        {
        "categoryid": 700,
        "categoryName": "Toys",
        "Image": "https://cdn.popshelf.com/content/dam/popshelf/category-images/toys/circle-image/Category_Toys_ActionFigures_002.jpg",
        },
        {
        "categoryid": 800,
        "categoryName": "Baby Products",
        "Image": "https://www.harrisonsdirect.co.uk/media/catalog/category/Bath_Skin_Care_Large.fw_1.png",
        },
]

const brandData = [
    {
        "Brandid": 1,
        "BrandName": "Mr Muscle",
        "Image": "https://pcutajar.com.mt/app/uploads/2022/06/Untitled-design.jpg",
        },
        {
        "Brandid": 2,
        "BrandName": "Dr Beckmann",
        "Image":"https://m.media-amazon.com/images/S/stores-image-uploads-eu-prod/c/AmazonStores/A1F83G8C2ARO7P/eca28255cd5374356ab56ef09d150089.w500.h500.png", 
        },
        {
        "Brandid": 3,
        "BrandName": "L’Oreal",
        "Image": "https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0000/2125/brand.gif?itok=9H79h1nR",
        },
        {
        "Brandid": 4,
        "BrandName": "Garnier",
        "Image": " https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Pc_O97lnVNj2TNpM7RNqsZSXCdp1mSnASHq4p9Q6_g&s",
        },
        {
        "Brandid": 5,
        "BrandName": "Nescafe",
        "Image": "https://logodix.com/logo/1087608.png",
        },
        {
        "Brandid": 6,
        "BrandName": "Nyx",
        "Image": "https://cdn2.f-cdn.com/contestentries/1957769/50015903/60db478152048_thumb900.jpg",
        },
]




export default function Homepage(){
    const [Catdata, setCatData] = useState(cdata);
    const [Bradata, setBraData] = useState(brandData);

    const feature =  allproducts.filter(item=>item.featureId=='yes')
    return(
        <>
        <div class="container-fluid mb-3">
        
            <div class="row mb-3 px-xl-3">
                <div className="col-lg-6 ">
                <div class="product-offer " style={{height: "400px"}}>
                    <img class="" src={require("./img/sale.jpg")} alt="" />
                    <div class="offer-text">
                        <h6 class="text-white text-uppercase">Save 30%</h6>
                        <h3 class="text-white mb-3">Special Offer</h3>
                        <a href="" class="btn btn-primary">Shop Now</a>
                    </div>
                </div>
                </div>
                <div className="col-lg-6 ">
                <div class="product-offer" style={{height: "400px"}}>
                    <img class="img-fluid" src={require("./img/sale2.jpg")} alt="" />
                    <div class="offer-text">
                        <h6 class="text-white text-uppercase">Save 20%</h6>
                        <h3 class="text-white mb-3">Special Offer</h3>
                        <a href="" class="btn btn-primary">Shop Now</a>
                    </div>
                </div>
                </div>
            </div>
          
            
            
        </div>
   

       
    


    <div class="container-fluid pt-5">
        <h2 class="section-title position-relative text-uppercase mx-xl-5 mb-4"><span class="bg-secondary pr-3">Categories</span></h2>
        <div class="row px-xl-5 pb-3">
        {
            Catdata.map(item=>{
                
               return( 
                    <div class="col-lg-3 col-md-4 col-sm-6 pb-1">
                        <a class="text-decoration-none" href="">
                        <Link to="/products" state={{ id:item.categoryid,catName:item.categoryName}}>
                            <div class="cat-item d-flex align-items-center mb-4">
                                <div class="overflow-hidden" style={{width: '100px', height: '100px'}}>
                                    <img class="img-fluid" src={item.Image} alt="" />
                                </div>
                                <div class="flex-fill pl-3">
                                    <h6>{item.categoryName}</h6>
                                    
                                </div>
                            </div>
                            </Link>
                        </a>
                   </div>
                    
        )})
        }
        </div>
        
           
    </div>


    <div class="container-fluid pt-5">
        <h2 class="section-title position-relative text-uppercase mx-xl-5 mb-4"><span class="bg-secondary pr-3">Brands</span></h2>
        <div class="row px-xl-5 pb-3">
        {
            Bradata.map(item=>{
                
               return( 
                    <div class="col-lg-3 col-md-4 col-sm-6 pb-1">
                        <a class="text-decoration-none" href="">
                            <div class="cat-item d-flex align-items-center mb-4">
                                <div class="overflow-hidden" style={{width: '100px', height: '100px'}}>
                                    <img class="img-fluid" src={item.Image} alt="" />
                                </div>
                                <div class="flex-fill pl-3">
                                    <h6>{item.BrandName}</h6>
                                    
                                </div>
                            </div>
                        </a>
                   </div>
                    
        )})
        }
        </div>
        
           
    </div>
    
   



    <div class="container-fluid pt-5 pb-3">
        <h2 class="section-title position-relative text-uppercase mx-xl-5 mb-4"><span class="bg-secondary pr-3">Featured Products</span></h2>
        <div class="row px-xl-5">
            {feature.map(item=>{
                return (
                    <div class="col-lg-3 col-md-4 col-sm-6 pb-1">
                    <div class="product-item bg-light mb-4">
                        <div class="product-img position-relative overflow-hidden">
                            <img class="img-fluid w-100" src={item.Image} alt="" />
                            <div class="product-action">
                                <a class="btn btn-outline-dark btn-square" href=""><i class="fa fa-shopping-cart"></i></a>
                                <a class="btn btn-outline-dark btn-square" href=""><i class="far fa-heart"></i></a>
                                <a class="btn btn-outline-dark btn-square" href=""><i class="fa fa-sync-alt"></i></a>
                                <a class="btn btn-outline-dark btn-square" href=""><i class="fa fa-search"></i></a>
                            </div>
                        </div>
                        <div class="text-center py-4">
                            <a class="h6 text-decoration-none " href="">{item.productName}</a>
                            <div class="d-flex align-items-center justify-content-center mt-2">
                                <h5>£{item.price}</h5><h6 class="text-muted ml-2"></h6>
                            </div>
                            <div class="d-flex align-items-center justify-content-center mb-1">
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small class="fa fa-star text-primary mr-1"></small>
                                <small>(99)</small>
                            </div>
                        </div>
                    </div>
                </div>
                )
            })}
           
            
        
            
           
        
         
         
        </div>
    </div>




    {/* <div class="container-fluid pt-5 pb-3">
        <div class="row px-xl-5">
            <div class="col-md-6">
                <div class="product-offer mb-30" style={{height: '300px'}}>
                    <img class="img-fluid" src={require("./img/offer-1.jpg")} alt="" />
                    <div class="offer-text">
                        <h6 class="text-white text-uppercase">Save 20%</h6>
                        <h3 class="text-white mb-3">Special Offer</h3>
                        <a href="" class="btn btn-primary">Shop Now</a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="product-offer mb-30" style={{height: '300px'}}>
                <img class="img-fluid" src={require("./img/offer-2.jpg")} alt="" />
                    <div class="offer-text">
                        <h6 class="text-white text-uppercase">Save 20%</h6>
                        <h3 class="text-white mb-3">Special Offer</h3>
                        <a href="" class="btn btn-primary">Shop Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
        </>
    )
}