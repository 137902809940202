// CartContext.js
import React, { createContext, useContext, useReducer } from 'react';

// Define initial state
const initialState = {
  cartItems: [],
};

// Define reducer function
const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        cartItems: state.cartItems.filter(item => item.product_id !== action.payload.product_id),
      };
      case 'UPDATE_ITEM_QUANTITY':
        return {
          ...state,
          cartItems: state.cartItems.map(item =>
            item.product_id === action.payload.id
              ? { ...item, quantity: action.payload.quantity }
              : item
          ),
        };
    default:
      return state;
  }
};

// Create CartContext
const CartContext = createContext();

// Create CartProvider component
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  return (
    <CartContext.Provider value={{ cartState: state, cartDispatch: dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use CartContext
export const useCart = () => {
  return useContext(CartContext);
};
