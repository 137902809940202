export const allproducts = [
    {
        "product_id":101, 
        "productName": " Nivea Care Shower Crème Soft 250ml ",
        "price": 2.30, 
        "categoryid": 100,
        "brandName": "Nivea",
        "featureId": "no",
        "quantity": 0,
        "Image": "https://www.onlinepoundstore.co.uk/wp-content/uploads/2021/04/Nivea-Shower-Cream-Soft-250ml-No-Banner.jpg",
        },
        {
        "product_id":102, 
        "productName": "Vaseline Lip Therapy Original 20g",
        "price": 1.29, 
        "categoryid": 100,
        "brandName": "Vaseline",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://youthstardirect.co.uk/cdn/shop/products/VASELINE_LIP_THERAPY_TIN_20G_-_POCKET_SIZE_TINS.jpg?v=1525362277",
        },
        {
        "product_id":103, 
        "productName": "Johnson’s Face Care Makeup Moisturizing Wipes Pack of 25",
        "price": 1.30, 
        "categoryid": 100,
        "brandName": "Johnson’s",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/41g00qr5McL._AC_.jpg",
        },
        {
        "product_id":104, 
        "productName": "Veet Cold Wax Strips Hair Removal Sensitive Skin 40 Strips",
        "price": 12.00, 
        "categoryid": 100,
        "brandName": "Veet",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/41OSZIWyusL._AC_.jpg",
        },
        {
        "product_id":105, 
        "productName": "Maybelline New York Mascara 9.4ml",
        "price": 6.54, 
        "categoryid": 100,
        "brandName": "Maybelline",
        "featureId": "yes",
        "quantity": 0,
        
        "Image": "https://www.maybelline.co.uk/-/media/project/loreal/brand-sites/mny/emea/uk/products/eye-makeup/mascara/lash-sensational-washable-mascara/maybelline-mascara-lash-sensational-blackest-black-041554420616-c.jpg?rev=fb4b5b45a1b2499da56135d088903853",
        },
        
        {
        "product_id":201, 
        "productName": "Andrex Gentle Clean Toilet Rolls – 45 Rolls",
        "price": 2.30, 
        "categoryid": 200,
        "brandName": "Andrex",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://appyshop.co.uk/product-images/vl/5029053571485.jpg",
        },
        {
        "product_id":202, 
        "productName": "Mr Muscle Drain Foamer 500ml",
        "price": 3.80, 
        "categoryid": 200,
        "brandName": "Mr Muscle",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/71Qvl2M6mYL.jpg",
        },
        {
        "product_id":203, 
        "productName": "Dr Beckmann Carpet Stain Remover 500ml",
        "price": 2.99, 
        "categoryid": 200,
        "brandName": "Dr Beckmann",
        "featureId": "yes",
        "quantity": 0,
        
        "Image": "https://static.thcdn.com//productimg/600/600/12827511-8094831603458945.png",
        },
        {
        "product_id":204, 
        "productName": "Tide Spring Meadow 20 Pacs",
        "price": 19.98, 
        "categoryid": 200,
        "brandName": "Tide",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/81Mv4DWbHxL._AC_UF1000,1000_QL80_.jpg",
        },
        {
        "product_id":205, 
        "productName": "HG Mould Spray 500ml",
        "price": 6.02, 
        "categoryid": 200,
        "brandName": "HG",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://cdn.hg.eu/media/dgkcpmvf/186050106-main.png?width=536&height=536&rnd=132845209234730000",
        },
        
        {
        "product_id":301, 
        "productName": " Flash Spray Wipe Done Apple Blossom 800ml ",
        "price": 2.50, 
        "categoryid": 300,
        "brandName": "Flash",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://myshop.co.uk/cdn/shop/products/b9a1a9c5-5597-4788-8707-3b0891836fbc_211x211.jpg?v=1622824490",
        },
        
        {
        "product_id":302, 
        "productName": "Elbow Grease Super Strong Cleaning Gloves",
        "price": 1.30, 
        "categoryid": 300,
        "brandName": "Elbow",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://myshop.co.uk/cdn/shop/products/dcec3767-b899-4e45-b6a0-778c31974f6d_211x211.jpg?v=1626943383",
        },
        {
        "product_id":303, 
        "productName": " Dri pak Soda Crystals 1KG ",
        "price": 1.75, 
        "categoryid": 300,
        "brandName": "Dri pak",
        "featureId": "yes",
        "quantity": 0,
        
        "Image": " https://myshop.co.uk/cdn/shop/products/6B989B16B50653965F87E417F720C2FD_211x211.jpg?v=1622822624",
        },
        {
        "product_id":304, 
        "productName": " Miracle Foaming Toilet Cleaner ",
        "price": 2.50, 
        "categoryid": 300,
        "brandName": "The Pink Stuff",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://myshop.co.uk/cdn/shop/files/259aef70-a14f-4cac-8756-c3f5dab2c4f2_211x211.jpg?v=1689666621",
        },
        {
        "product_id":305, 
        "productName": "Carpet Machine Solution 500ml ",
        "price": 2.00, 
        "categoryid": 300,
        "brandName": "1001",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://myshop.co.uk/cdn/shop/products/1001CarpetMachineSolution500ml_512x512.png?v=1637578731",
        },
        
        {
        "product_id":401, 
        "productName": "HyClean 3D Efficiency GN Vacuum Cleaner Bags Pack of 4",
        "price": 13.29, 
        "categoryid": 400,
        "brandName": "Hy Clean",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://images-eu.ssl-images-amazon.com/images/I/71UanVE2z9L.AC_UL600_SR600,400.jpg",
        },
        {
        "product_id":402, 
        "productName": "Basic Slim Velvet Hangers – Pack of 30",
        "price": 13.33, 
        "categoryid": 400,
        "brandName": "Basics",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://images-eu.ssl-images-amazon.com/images/I/71lbl6YU5PL.AC_UL600_SR600,400.jpg",
        },
        {
        "product_id":403, 
        "productName": "Brita Fridge Water Filter Jug 2.4L",
        "price": 29.44, 
        "categoryid": 400,
        "brandName": "Brita",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://images-eu.ssl-images-amazon.com/images/I/71WafloZdYL.AC_UL600_SR600,400.jpg ",
        },
        {
        "product_id":404, 
        "productName": "Russell Hobbs Powerstream Iron",
        "price": 40.94, 
        "categoryid": 400,
        "brandName": "Russell",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://images-eu.ssl-images-amazon.com/images/I/81UQH2JNH+L.AC_UL300_SR300,200.jpg ",
        },
        {
        "product_id":405, 
        "productName": "Breville Blend Active Blender Plus Active Bottle",
        "price": 23.00, 
        "categoryid": 400,
        "brandName": "Breville",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://images-eu.ssl-images-amazon.com/images/I/61NFIfk2hbL.AC_UL600_SR600,400.jpg ",
        },
        
        {
        "product_id":501, 
        "productName": "Helix Oxford Twin pack of Erasers",
        "price":1.00, 
        "categoryid": 500,
        "brandName": "Helix",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/81huaz5yxUL.AC_SY879.jpg",
        },
        {
        "product_id":502, 
        "productName": "BIC Crystal Ballpoints Pens Pack of 50",
        "price":12.90, 
        "categoryid": 500,
        "brandName": "BIC",
        "featureId": "yes",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/81S4cndRaXL.AC_SY879.jpg",
        },
        {
        "product_id":503, 
        "productName": "Helix Oxford Math Set",
        "price": 2.50, 
        "categoryid": 500,
        "brandName": "Helix",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/81d8oAuiGAL.AC_SX569.jpg",
        },
        {
        "product_id":504, 
        "productName": "Casio FX-83GTCW Black Scientific Calculator",
        "price": 10.70, 
        "categoryid": 500,
        "brandName": "Casio",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/71jeBCw4QjL.AC_SX569.jpg",
        },
        {
        "product_id":505, 
        "productName": "Sharpie Fluo XL Highlighters – 4 count",
        "price":2.50, 
        "categoryid": 500,
        "brandName": "Sharpie",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/81cnCI+m-8L.AC_SX569.jpg",
        },
                             
        {
        "product_id":601, 
        "productName": "Gloves Black Pear Nitrile GP0033 Box of 100",
        "price": 6.43, 
        "categoryid": 600,
        "brandName": "",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/41F1Lr3I1nL.SY445_SX342_QL70_ML2.jpg",
        },
        {
        "product_id":602, 
        "productName": "Coral Paint Brushes Set of 3",
        "price": 5.97, 
        "categoryid": 600,
        "brandName": "Coral",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/81py8opAjdL.AC_SX425.jpg",
        },
        {
        "product_id":603, 
        "productName": "White Electric Extension Lead 4 Gang 2 Meter",
        "price": 8.04, 
        "categoryid": 600,
        "brandName": " Desiretech",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/51TfsYsqm2L.AC_SX425.jpg",
        },
        {
        "product_id":604, 
        "productName": "Gorilla Super Glue 15g",
        "price": 10.79, 
        "categoryid": 600,
        "brandName": "Gorilla",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/81GVBVWnryS.AC_SX425.jpg",
        },
        {
        "product_id":605, 
        "productName": "Merrimen Brown Packaging Tape (48mm x 66mm) 3 rolls Brown",
        "price": 5.99, 
        "categoryid": 600,
        "brandName": "Merrimen",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/61hvK7FmM3L.SX466.jpg",
        },
        
        {
        "product_id":701, 
        "productName": "Mattel Games UNO",
        "price":13.19, 
        "categoryid": 700,
        "brandName": "Mattel Games",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/71hPR5JB0cL.AC_SX425.jpg",
        },
        {
        "product_id":702, 
        "productName": "Monopoly Deal Card Game",
        "price": 6.01, 
        "categoryid": 700,
        "brandName": "Monopoly",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/818aHOXFPAL.AC_SY355.jpg",
        },
        {
        "product_id":703, 
        "productName": "Pokemon Assorted Cards 50 Pieces",
        "price": 4.85, 
        "categoryid": 700,
        "brandName": "Pokemon",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/614ngmgsIcL.AC_SX425.jpg",
        },
        {
        "product_id":704, 
        "productName": "Rainbow Pop it Fidget Toy",
        "price": 3.05, 
        "categoryid": 700,
        "brandName": "Colors",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/715lfsnoQZL.AC_SY355.jpg ",
        },
        {
        "product_id":705, 
        "productName": "Yumuwind Suction Cup Spinner Toys",
        "price": 8.49, 
        "categoryid": 700,
        "brandName": "Yumuwind",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/61btpFyf3FL.AC_SX425.jpg",
        },
        
        {
        "product_id":801, 
        "productName": "Johnsons Baby Cotton Buds Pack of 200",
        "price": 1.48, 
        "categoryid": 800,
        "brandName": "Johnsons",
        "featureId": "no",
        "quantity": 0,
        
        "Image": "https://m.media-amazon.com/images/I/61yFxG+Ro1L.AC_SX569.jpg",
        },
        {
        "product_id":802, 
        "productName": "Tommee Tippee Baby Bottle Teats pack of 2",
        "price": 5.49, 
        "categoryid": 800,
        "brandName": "Tommee Tippee",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/71sXzBVcd3L.AC_SX569.jpg ",
        },
        {
        "product_id":803, 
        "productName": "Johnson’s Baby Bedtime Powder 500g",
        "price": 2.36, 
        "categoryid": 800,
        "brandName": "Johnson’s",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/61h7aaIfLoL.AC_SY450.jpg ",
        },
        {
        "product_id":804, 
        "productName": "Johnson’s Baby Oil 500ml",
        "price": 2.99, 
        "categoryid": 800,
        "brandName": "Johnson’s",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/51VveGpRcaL.AC_SY450.jpg ",
        },
        {
        "product_id":805, 
        "productName": "MAM Night Soothers Pack of 2",
        "price": 8.25, 
        "categoryid": 800,
        "brandName": "MAM",
        "featureId": "no",
        "quantity": 0,
        
        "Image": " https://m.media-amazon.com/images/I/51+Mi--jjwL.AC_SY300_SX300.jpg ",
        },
]